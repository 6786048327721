import React from "react"
import Loader from "react-loader-spinner"
import { StyledLoader } from "./Loader.styled"
import { theme } from "../../styles/theme"

const LoaderComponent: React.FC = () => (
  <StyledLoader>
    <Loader
      type="Oval"
      color={theme.primary}
      height={100}
      width={100}
      timeout={3000}
    />
  </StyledLoader>
)

export default LoaderComponent
