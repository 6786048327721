import React, { Suspense, lazy } from "react"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import SEO from "../components/seo"
import { Section, Stars } from "../components/section"
import Loader from "../components/loader/Loader"
import { QueryPagesProps } from "../types/interface"

const HomeHeader = lazy(() => import("../components/header/HomeHeader"))
const Layout = lazy(() => import("../components/layout/Layout"))

const IndexPage: React.FC<PageProps> = () => {
  const data: QueryPagesProps = useStaticQuery(graphql`
    query {
      gcms {
        sections(where: { whichSite_in: main }) {
          richText {
            html
          }
          title
          text
          isStars
          index
          id
          imageUrl
          buttonText
          buttonUrl
          darkMode
        }
        seoDetails(where: { witchSite_in: main }) {
          siteTitle
          description
        }
      }
    }
  `)
  const sectionsData = data.gcms.sections.sort((a, b) => a.index - b.index)
  const isSSR = typeof window === "undefined"

  const { siteTitle, description } = data.gcms.seoDetails[0]

  if (isSSR) return null

  return (
    <Suspense fallback={<Loader />}>
      <Layout isMainPage>
        <SEO title={siteTitle} description={description} />
        <HomeHeader />
        {sectionsData.map(
          ({
            title,
            text,
            buttonText,
            buttonUrl,
            darkMode,
            imageUrl,
            id,
            index,
            isStars,
            richText,
          }) => (
            <Section
              key={id}
              title={title}
              text={text}
              richText={richText && richText.html}
              buttonText={buttonText}
              buttonUrl={buttonUrl}
              darkBackground={darkMode}
              imgUrl={imageUrl}
              index={index}
              id={id}
            >
              {isStars && <Stars />}
            </Section>
          )
        )}
      </Layout>
    </Suspense>
  )
}

export default IndexPage
